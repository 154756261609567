import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Home = () => import('@/views/Home')
const About = () => import('@/views/About')
const Faq = () => import('@/views/Faq')
const Promotions = () => import('@/views/Promotions')
const Games = () => import('@/views/Games')

Vue.use(Router)

export default new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/home',
      name: 'Default',
      component: TheContainer,
      children: [
        {
          path: 'home',
          name: 'Home',
          component: Home
        },
        {
          path: 'about',
          name: 'About',
          component: About
        },
        {
          path: 'faq',
          name: 'Faq',
          component: Faq
        },
        {
          path: 'promotions',
          name: 'Promotions',
          component: Promotions
        },
        {
          path: 'games/:gametype',
          name: 'Games',
          component: Games
        }
      ]
    }
  ]
}

